.spinner {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top-color: #F4F3FA;
  opacity: 0;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  width: 90px;
  height: 90px;
  transition: opacity 250ms;
  animation: rotate-spinner 1s linear;
  animation-iteration-count: infinite;
}

.spinner {
  opacity: 1;
  transition-delay: 250ms;
}

@keyframes rotate-spinner {
  100% {
    transform: rotate(360deg);
}
}
